<template>
  <section class="navigation">
        <div class="nav-mobilee"><a id="nav-toggle" href="#!"><span></span></a></div>    
  <div class="nav-container">
      <router-link to='/'>
        <!-- <img src=" http://127.0.0.1:8000/media/logo/MkW.jpg"> -->
        <img src="https://mkw-rdc.hogi.edu.bi/media/logo/MkW.jpg"> 
        
      </router-link>
    <nav>
      <ul class="nav-list">
        <li>
          <router-link class="nav-link" to="/">à propos <span class="sr-only">(current)</span></router-link>
        </li>
        <li>
          <a href="#!">Ce que nous sommes</a>
          <ul class="nav-dropdown">
            <li>
            <router-link class="dropdown-item" to="/presentation">Présentation</router-link>
            </li>
            <li>
                <router-link class="dropdown-item" to="/equipe">Equipe Technique</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#!">Domaines d'intervention</a>
          <ul class="nav-dropdown">
            <li><router-link class="dropdown-item" to="/agriculture">Agriculture et securité alimentaire</router-link></li>
            <li><router-link class="dropdown-item" to="/droits-humains">Droits humains et citoyenneté active</router-link></li>
            <li><router-link class="dropdown-item" to="/education">Education              </router-link></li>
            <li><router-link class="dropdown-item" to="/environnement">Environnement et bonne gestion des ressources naturelles</router-link></li>
            <li><router-link class="dropdown-item" to="/renforcement">Renforcement du pouvoir economique des jeunes et des femmes</router-link></li>
            <li><router-link class="dropdown-item" to="/eau-hygiene">Eau, Hygiène et assainissement</router-link></li>
            <li><router-link class="dropdown-item" to="/sante-nutrition">Santé et nutrition</router-link></li>
            <li><router-link class="dropdown-item" to="/inclusion">Inclusion des personnes à besoin specifique</router-link></li>
          </ul>
        </li>
        <li>
          <a href="#!">Nos projets</a>
          <ul class="nav-dropdown">
            <li><router-link class="dropdown-item" to="/projet_passe">Projets passés</router-link></li>
            <li>
              <router-link class="dropdown-item" to="/projet_en_cours">Projets en cours</router-link></li>
          </ul>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">Contacts</router-link>
          </li>
          <li class="nav-item">
              <!-- <button style="background-color: red"> -->
               <router-link class="nav-link" style="background-color: red; " to="/don/"><span style="font-size:12px;">Faire un don </span></router-link>                
              <!-- </button> -->
            </li>
      </ul>
    </nav>
  </div>
</section>
</template>
<script>
  import Hotbar from "../components/Hotbar2"
  import axios from "axios"
  export default{
    components:{Hotbar},
    data(){
      return {
        logo:this.$store.state.logo,
      }
    },
    mounted() {
        axios.get(this.$store.state.url + "/logo/")
            .then(res => {
              this.logo = res.data
              console.log(res.data)
            })
            .catch(err => {
                console.error(err); 
            })
        },  
  }
</script>
<style scoped>
  .navigation {
  height: 70px;
  background: #262626;
}
.brand {
  position: absolute;
  padding-left: 20px;
  float: left;
  line-height: 70px;
  text-transform: uppercase;
  font-size: 1.4em;
}
.brand a, .brand a:visited {
  color: #ffffff;
  text-decoration: none;
}
.nav-container {
  max-width: 1000px;
  margin: 0 auto;
}
nav {
  float: right;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav ul li {
  float: left;
  position: relative;
}
nav ul li a, nav ul li a:visited {
  display: block;
  padding: 0 20px;
  line-height: 70px;
  background: #262626;
  color: #ffffff;
  text-decoration: none;
}
nav ul li a:hover, nav ul li a:visited:hover {
  background: #9c27b0;
  color: #ffffff;
}
nav ul li a:not(:only-child):after, nav ul li a:visited:not(:only-child):after {
  padding-left: 4px;
  content: ' ▾';
}
nav ul li ul li {
  min-width: 100%;
}
nav ul li ul li a {
  padding: 15px;
  line-height: 20px;
}
.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}
/* Mobile navigation */
.nav-mobile {
  display: none;
  position: absolute;
  background: #262626;
  height: auto;
  width: auto;
}
@media only screen and (max-width: 798px) {
  .nav-mobile {
    display: block;
    float: right;
  }
  nav {
    width: 100%;
    padding: 70px 0 15px;
  }
  nav ul {
    display: none;
  }
  nav ul li {
    float: none;
  }
  nav ul li a {
    padding: 15px;
    line-height: 20px;
  }
  nav ul li ul li a {
    padding-left: 30px;
  }
  .nav-dropdown {
    position: static;
  }
}
@media screen and (min-width: 799px) {
  .nav-list {
    display: block !important;
  }
}
#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}
#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: #ffffff;
  position: absolute;
  display: block;
  content: '';
  transition: all 300ms ease-in-out;
}
#nav-toggle span:before {
  top: -10px;
}
#nav-toggle span:after {
  bottom: -10px;
}
#nav-toggle.active span {
  background-color: transparent;
}
#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0;
}
#nav-toggle.active span:before {
  transform: rotate(45deg);
}
#nav-toggle.active span:after {
  transform: rotate(-45deg);
}
  img{
    width: 80px;
    height: 60px;
  }
  img{
    padding-right: 25px;
    margin: 5px;
  }
@media screen and (max-width: 600px) {
  .web {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
@media (max-width: 600px) {
  img{
    width: 80px;
    height: 60px;
    float: right;
  }
  img{
    padding-right: 25px;
    margin: 5px;
    float: right;
  }
}
@media only screen and (max-width: 798px) {
    nav{
        width: 100%;
        padding: 0px 0 15px;
    }
}
@media screen and (min-width: 760px) {
  .nav-mobilee {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>