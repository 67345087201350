<template>
<div class="contact">
<div class="about-section">
  <h1>NOS PARTENAIRES</h1>
</div>

<section class="section section-default mt-none mb-none">
<div class="container">
<strong>
<div class="row">
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder" v-for = "part in partenaires" :key = "part.id">
  <a href="https://www.incontrofraipopoli.it/" target="blank" title="">
    <img alt="" :src="part.image" />
  </a>
</div>
</div>
</div>
</strong>
</div>
</section>
</div>
</template>
<script>
  import axios from "axios"
  export default{
    data(){
      return {
        partenaires:this.$store.state.partenaires,
      }
    },
    mounted() {
        axios.get(this.$store.state.url + "/partenaire/")
            .then(res => {
              this.partenaires = res.data
              console.log(res.data)
            })
            .catch(err => {
                console.error(err); 
            })
        },  
  }
</script>
<style scoped>
.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}

.square-holder {
  padding:30px;
  border: 1px solid #cecece;
  align-items: center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:20px;
  background-color:#f1f1f1;
  min-height:200px
}

.square-holder img {
  max-width:100%;
/*  filter: grayscale(100%);*/
  transition: all 0.3s;
}

.square-holder:hover img{
  filter:none;
}
</style>