<template>
<div class="contact">
<div class="about-section">
  <h1> Inclusion des personnes à besoins spécifiques</h1>
</div>
<div>
  <!-- <img src='http://localhost:8000/media/galerie/Photo_Inclusio2n.jpg'> -->
  <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/Photo_Inclusio2n.jpg'>
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container" style="text-align: justify;">
        <p style="font-size: 16px;">
          MkW prend en considération les conditions de vie des personnes en situation de handicap, en tenant compte de divers facteurs (renforcement de capacités, réhabilitation physique, assistance sanitaire, nutritionnelle, etc.). Il organise de séances de sensibilisation sur l’inclusion des personnes à besoins spécifiques dans la communauté. Les enfants vivant avec handicap sont insérés dans les écoles inclusives et leurs soins sont pris en charge. Les adultes sont aussi assistés dans leur réhabilitation physique et leur prise en charge sanitaire. MkW se bat pour que la communauté devienne inclusive.
        </p>
      </div>
    </div>
  </div>
</div>
<carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>

<style scoped>
/*.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}*/

.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column  {
    width: 100%;
    display: block;
  }
}
img{
  width: 100%;
}
</style>
