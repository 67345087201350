import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Equipe from '../views/Equipe.vue'
import Mission from '../views/Mission.vue'
import Objectifs from '../views/Objectifs.vue'
import Presentation from '../views/Presentation.vue'
import ProjetEncours from '../views/ProjetEncours.vue'
import ProjetPasse from '../views/ProjetPasse.vue'
import Vision from '../views/Vision.vue'
import Don from '../views/Don.vue'
import Contact from '../views/Contact.vue'
import Rapport from '../views/Rapport.vue'
import Partenaires from '../views/Partenaires.vue'
import Valeurs from '../views/Valeurs.vue'

import Actualites from '../views/Actualites.vue'
import Policies from '../views/Policies.vue'
import Programme from '../views/Programme.vue'
import Activites from '../views/Activites.vue'

import Agriculture from '../views/Agriculture.vue'
import Humans from '../views/Humans.vue'
import Education from '../views/Education.vue'
import Environnement from '../views/Environnement.vue'
import Renforcement from '../views/Renforcement.vue'
import Sante from '../views/Sante.vue'
import Inclusion from '../views/Inclusion.vue'
import Eau from '../views/Eau.vue'
import Strategies from '../views/Strategies.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/equipe',
    name: 'equipe',
    component: () => import( '../views/Equipe.vue')
  },
  {
    path: '/mission',
    name: 'mission',
    component: () => import( '../views/Mission.vue')
  },
  {
    path: '/objectifs',
    name: 'objectifs',
    component: () => import( '../views/Objectifs.vue')
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: () => import( '../views/Presentation.vue')
  },
  {
    path: '/strategie',
    name: 'strategie',
    component: () => import( '../views/Strategies.vue')
  },
  {
    path: '/projet_passe',
    name: 'projet_passe',
    component: () => import( '../views/ProjetPasse.vue')
  },
  {
    path: '/projet_en_cours',
    name: 'projet_en_cours',
    component: () => import( '../views/ProjetEncours.vue')
  },
  {
    path: '/vision',
    name: 'vision',
    component: () => import( '../views/Vision.vue')
  },
  {
    path: '/don/',
    name: 'don',
    component: () => import( '../views/Don.vue')
  },
  {
    path: '/rapports',
    name: 'rapports',
    component: () => import( '../views/Rapport.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/programmes',
    name: 'programmes',
    component: () => import( '../views/Programme.vue')
  },
  {
    path: '/activites',
    name: 'activites',
    component: () => import( '../views/Activites.vue')
  },
  {
    path: '/actualites',
    name: 'actualites',
    component: () => import( '../views/Actualites.vue')
  },
  {
    path: '/politiques',
    name: 'politiques',
    component: () => import( '../views/Policies.vue')
  },
  {
    path: '/partenaires',
    name: 'partenaires',
    component: () => import( '../views/Partenaires.vue')
  },
  {
    path: '/sante-nutrition',
    name: 'sante-nutrition',
    component: () => import( '../views/Sante.vue')
  },
  {
    path: '/agriculture',
    name: 'agriculture',
    component: () => import( '../views/Agriculture.vue')
  },
  {
    path: '/droits-humains',
    name: 'droits-humains',
    component: () => import( '../views/Humans.vue')
  },
  {
    path: '/education',
    name: 'education',
    component: () => import( '../views/Education.vue')
  },
  {
    path: '/environnement',
    name: 'environnement',
    component: () => import( '../views/Environnement.vue')
  },
  {
    path: '/renforcement',
    name: 'renforcement',
    component: () => import( '../views/Renforcement.vue')
  },
  {
    path: '/eau-hygiene',
    name: 'eau-hygiene',
    component: () => import( '../views/Eau.vue')
  },
  {
    path: '/inclusion',
    name: 'inclusion',
    component: () => import( '../views/Inclusion.vue')
  },
  {
    path: '/valeur',
    name: 'valeur',
    component: () => import( '../views/Valeurs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
