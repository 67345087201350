<template>
<div class="contact">
<div class="about-section">
  <h1>NOS RAPPORTS</h1>
</div>

<section class="section section-default mt-none mb-none">
<div class="container">
<div class="row">
<div class="col-sm-6 col-md-4 col-lg-3"  v-for = "partenaire in rapports" :key='partenaire.id'>
  <a :href="partenaire.rapport" target="blank" title="">
    <div class="square-holder" >
        <img alt="" :src="partenaire.thumbnail"/>
    </div>
    </a>
  </div>
</div>
</div>
</section>
</div>
</template>
<script>
  import axios from "axios"
  export default{
    data(){
      return {
        rapports:this.$store.state.rapports,
      }
    },
    mounted() {
        axios.get(this.$store.state.url + "/rapport/")
            .then(res => {
              this.rapports = res.data
              console.log(res.data)
            })
            .catch(err => {
                console.error(err); 
            })
        },  
  }
</script>
<style scoped>
.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}

.square-holder {
  padding:30px;
  border: 1px solid #000;
  align-items: center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:20px;
  background-color:#f1f1f1;
  min-height:200px
}

.square-holder img {
  max-width:100%;
  /*filter: grayscale(100%);*/
  transition: all 0.3s;
}

.square-holder:hover img{
  filter:none;
}
</style>