<template>
<div class="contact">
<div class="about-section">
  <h1> Agriculture et sécurité alimentaire</h1>
</div>
<div>
  <!-- <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29_1.jpeg'> -->
  <!-- <img src='https://mkw-rdc.org/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29_1.jpeg'> -->
  <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29_1.jpeg'>
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container" style="text-align: justify;">
        <p style="font-size: 16px;">
          Nous réalisons des programmes spécifiques pour renforcer les compétences locales en matière d'agriculture, qui représente la principale source de subsistance de ces communautés, en promouvant les techniques agricoles résilientes au changement climatique et durables afin d'améliorer la fertilité des sols et de garantir de meilleures récoltes.
        </p>
        <p>La productivité des terres contribue à enrichir le régime alimentaire, ce qui représente une priorité urgente dans une région qui enregistre des niveaux dramatiques de malnutrition. C'est pour cette raison que MkW a créé et structuré des oopératives agricoles et des ménages vulnérables au tour des filières agricoles porteuses afin de prévenir et de surveiller la malnutrition.
        </p>
        <p>L’équipe technique de MkW forme et vulgarise des techniques durables de production agropastorale, appui en intrants de production maraichère, vivrière et d’élevage, intégration agriculture-élevage, introduction des matériaux et espèces performants, les manifestations promotionnelles, la santé et production animale.
        </p>
        <p>
          Dans ce domaine nous sommes beaucoup plus impliqué dans :
          <ul>
            <li>Accompagnement des coopératives agricoles et ménages vulnérables par la mise place des jardins familiaux;</li>
            <li>Aménagement des routes de dessertes agricoles et canaux d’irrigation;</li>
            <li>Distribution des vivres;</li>
            <li>Renforcement des capacités des bénéficiaires.</li>
          </ul>
        </p>
      </div>
    </div>
  </div>
</div>
<Carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>

<style scoped>
.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}
img {
  width:100%
}


@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
</style>