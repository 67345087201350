<template>
  <div id="app">
    <hotbar class="web"/>
    <navbar></navbar>
    <router-view/>
    <Footer ></Footer>
  </div>
</template>
<script>
  import Navbar from "@/components/Navbar2"
  import Hotbar from "@/components/Hotbar2"
  import Footer from "@/components/Footer"
  export default{
    components:{Footer, Navbar, Hotbar},
      computed:{
        logged_in() {
          return this.$store.state.user != null;
        }
      },

      mounted(){
        var user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          this.$store.state.user = user;
        } else {
          console.warn("il y'a pas de session");
        }
      },
      watch:{
        "$store.state.user":{
          deep: true,
          handler(new_user){
            localStorage.setItem('user', JSON.stringify(new_user));
          }
        }
      },

  }
</script>
<style >
  #app {
    width: 100%;
    height: 100%;
  }
  h1{
    font-size: 24px;
    padding: 5px;
  }
@media screen and (max-width: 600px) {
  .web {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
img{
  width: 100%;
  padding: 10px;
}

</style>