import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    // url:"/api",
    // url:"https://https://mkw-rdc.nkokoz.com/api",
    url:"https://mkw-rdc.hogi.edu.bi/api",
    // url:"http://127.0.0.1:8000/api",
    // url: 'http://localhost:8000/api',
    logo:[],
    visions:[],
    missions:[],
    equipes:[],
    programmes:[],
    objectifs:[],
    projetpasses:[],
    projetencours:[],
    rapports:[],
    partenaires:[]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
